<template>
    <div class="laboratory">
        <div class="main" v-if="isLogin">
            <!--   <div class="side-fixed">

               </div>
               <div class="detail">
                   <div class="content">
                       <router-view />
                   </div>
               </div>-->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-steps :active="stepActive" simple>
                        <el-step title="填写实验室信息" icon="el-icon-edit"></el-step>
                        <el-step title="待审核" icon="el-icon-success"></el-step>
                        <!--                        <el-step title="" icon="el-icon-picture"></el-step>-->
                    </el-steps>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" v-if="stepActive == 1">
                    <h3 style="text-align: center">认证信息</h3>
                    <el-form-item label="营业执照：" required prop="license_url">
<!--                        <el-upload-->
<!--                                action="#"-->
<!--                                list-type="picture-card"-->
<!--                                :limit="1"-->
<!--                                :before-upload="beforeUpload('license_url')"-->
<!--                                :auto-upload="false">-->
<!--                            <i slot="default" class="el-icon-plus"></i>-->
<!--                            <div slot="default" slot-scope="{file}">-->
<!--                                <img-->
<!--                                        class="el-upload-list__item-thumbnail"-->
<!--                                        :src="file.url" alt=""-->
<!--                                >-->
<!--                                <span class="el-upload-list__item-actions">-->
<!--                                <span-->
<!--                                        class="el-upload-list__item-preview"-->
<!--                                        @click="handlePictureCardPreview(file)"-->
<!--                                >-->
<!--                                  <i class="el-icon-zoom-in"></i>-->
<!--                                </span>-->
<!--                                <span-->
<!--                                        v-if="!disabled"-->
<!--                                        class="el-upload-list__item-delete"-->
<!--                                        @click="handleDownload(file)"-->
<!--                                >-->
<!--                                  <i class="el-icon-download"></i>-->
<!--                                </span>-->
<!--                                <span-->
<!--                                        v-if="!disabled"-->
<!--                                        class="el-upload-list__item-delete"-->
<!--                                        @click="handleRemove(file)"-->
<!--                                >-->
<!--                                  <i class="el-icon-delete"></i>-->
<!--                                </span>-->
<!--                              </span>-->
<!--                            </div>-->
<!--                        </el-upload>-->
                        <el-upload
                                action="#"
                                class="avatar-uploader"
                                :show-file-list="false"
                                :before-upload="beforeUpload('license_url')"
                                accept="image/*"
                        >
                            <div slot="tip" class="el-upload__tip">请上传清晰图片，支持格式gif jpg jpeg png且不大于5MB</div>
                            <div v-if="ruleForm.license_url">
                                <el-button class="preview" icon="el-icon-search" circle @click.native.stop="handlePictureCardPreview($imgurl + ruleForm.license_url)"></el-button>
                                <img  :src="$imgurl + ruleForm.license_url" class="avatar">
                            </div>


                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                 <!--       <div style="width: 30%;text-align: center">

                        </div>-->

                    </el-form-item>
<!--                    <el-row>-->
<!--                        <el-col :span="12">-->
<!--                            <el-form-item label="CNAS证书："  required prop="certificate_url">-->
<!--                                <el-upload-->
<!--                                        action="#"-->
<!--                                        class="avatar-uploader"-->
<!--                                        :show-file-list="false"-->
<!--                                        :before-upload="beforeUpload('cnas_url')"-->
<!--                                        accept="image/*"-->
<!--                                >-->
<!--                                    <div v-if="ruleForm.cnas_url">-->
<!--                                        <el-button class="preview" icon="el-icon-search" circle @click.native.stop="handlePictureCardPreview($imgurl + ruleForm.cnas_url)"></el-button>-->
<!--                                        <img  :src="$imgurl + ruleForm.cnas_url" class="avatar">-->
<!--                                    </div>-->
<!--                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--                                </el-upload>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="12">-->
<!--                            <el-form-item label="CMA证书："   prop="cma_url">-->
<!--                                <el-upload-->
<!--                                        class="avatar-uploader"-->
<!--                                        :show-file-list="false"-->
<!--                                        :before-upload="beforeUpload('cma_url')"-->
<!--                                        accept="image/*"-->
<!--                                        action="#"-->
<!--                                >-->
<!--                                    <div v-if="ruleForm.cma_url">-->
<!--                                        <el-button class="preview" icon="el-icon-search" circle @click.native.stop="handlePictureCardPreview($imgurl + ruleForm.cma_url)"></el-button>-->
<!--                                        <img  :src="$imgurl + ruleForm.cma_url" class="avatar">-->
<!--                                    </div>-->

<!--                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--                                </el-upload>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="24">-->
<!--                            <el-form-item label="证书上传说明：" >-->
<!--                                <div class="el-upload__tip" style="margin: 0">CNAS和CMA证书至少上传一项，请上传清晰图片，支持格式gif jpg jpeg png且不大于5MB</div>-->
<!--                            </el-form-item>-->

<!--                        </el-col>-->
<!--                    </el-row>-->
                    <el-form-item label="企业名称："  required prop="company_name">
                        <el-input v-model="ruleForm.company_name" ></el-input>
                    </el-form-item>
<!--                    <el-form-item label="统一社会信用代码："  required prop="license_no">-->
<!--                        <el-input v-model="ruleForm.license_no" ></el-input>-->
<!--                    </el-form-item>-->
                    <h3 style="text-align: center">联系人信息</h3>

                    <el-form-item label="姓名：" required  prop="name">
                        <el-input v-model="ruleForm.name" style="width: 200px;margin-right: 5px"></el-input>
                    </el-form-item>
      <!--              <el-form-item label="身份证号码：" required  prop="idcard">
                        <el-input v-model="ruleForm.idcard" style="width: 200px;margin-right: 5px"></el-input>
                    </el-form-item>-->
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="手机号码："  required prop="phone">
                                <el-input v-model="ruleForm.phone"  style="width: 200px;margin-right: 5px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
<!--                            <el-form-item label="验证码：" required  prop="code">-->
<!--                                <el-input v-model="ruleForm.code" style="width: 100px;margin-right: 5px"></el-input>-->
<!--                                <el-button style="margin-left: 5px" @click.native="getMobileCode('check', ruleForm.phone)" type="text">{{ times > 0 ? `${times}秒后获取`: '获取验证码'}}</el-button>-->
<!--                            </el-form-item>-->
                        </el-col>
                    </el-row>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">提交申请</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                </el-form>
                <div v-else>
                    <el-result icon="success" title="提交成功" subTitle="您的入驻申请提交成功，工作人员将在2个工作日内与您沟通。">
                        <template slot="extra">
                            <el-button type="primary" @click="goIndex()" size="medium">回首页</el-button>
                        </template>
                    </el-result>
                </div>
            </el-card>
        </div>
        <div v-else style="margin-top: 150px">
            <el-empty description="登录后可继续操作">
                <el-button type="danger" @click="$login.show()">去登录</el-button>
            </el-empty>
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>

</template>

<script>
    import {CodeToText, provinceAndCityDataPlus, regionData} from "element-china-area-data";
    import {setTimeDown} from "../../utils/storageUtils";
    import {sendcode, upload} from "../../api/common";
    import {UserService} from "../../api/user";
    import {  mapGetters } from 'vuex'
    export default {
        name: "demand",
        data() {
            return {
                options: regionData,
                stepActive: 1,
                times: 0,
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                ruleForm: {
                    license_url: '',
                    cnas_url: '',
                    cma_url: '',
                    certificate_url: '',
                    company_name: '',
                    license_no: '',
                    name: '',
                    phone: '',
                    code: '',
                    idcard: ''
                },
                fileList: [],
                rules: {
                    license_url: [
                        { required: true, message: '请上传营业执照影印件图', trigger: 'change' },
                    ],
                    certificate_url: [
                        { required: true, message: 'CNAS和CMA证书必须上传一项', trigger: 'change' }
                    ],
                    company_name: [
                        { required: true, message: '请填写企业名称', trigger: 'blur' },
                    ],
                    license_no: [
                        { required: true, message: '请填写营业执照号', trigger: 'blur' },
                    ],
                    name: [
                        { required: true, message: '请填写姓名', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请填写手机号码', trigger: 'blur' },
                    ],
                    idcard: [
                        { required: true, message: '请填写身份证号码', trigger: 'blur' },
                    ],
                    // code: [
                    //     { required: true, message: '请填写手机验证码', trigger: 'blur' },
                    // ],
                }
            }
        },
      computed: {
        ...mapGetters(['isLogin']),
      },
        methods: {
            test() {
                alert(222)
            },
            goIndex() {
                this.$router.push({
                    path: '/'
                })
            },
            countdown() {
                this.times = 60
                // eslint-disable-next-line no-unused-vars
                const down = () => {
                    setTimeout(() => {
                        this.times--
                        setTimeDown(this.times)
                        if (this.times > 0) {
                            down()
                        }
                    }, 1000)
                }
                down()

            },
            getMobileCode(event, mobile) {
                if (this.times > 0) return
                sendcode({ event, mobile }).then(res => {
                    // // console.log('res', res)
                    if (res.code) {
                        this.$message.success('验证码发送成功')
                        this.countdown()
                    } else {
                        this.$message.error(res.msg)

                    }
                    // this.captchaUrl = res.data
                })
            },
            beforeUpload(type) {
                return (file) => {
                    // // console.log(file);
                    const forms = new FormData()
                    forms.append('file', file)
                    upload(forms).then(res => {
                        if (res.code == 1) {
                            this.ruleForm[type] = res.data.url
                        } else {
                            this.$message.error(res.msg)
                        }

                    })
                }

            },
            handleRemove(file) {
                // // console.log(file);
            },
            handlePictureCardPreview(url) {
                this.dialogImageUrl = url;
                this.dialogVisible = true;
            },
            handleDownload(file) {
                // // console.log(file);
            },
            submitForm(formName) {
                this.ruleForm.certificate_url = this.ruleForm.cnas_url || this.ruleForm.cma_url
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm(`确定提交已填信息？`).then(res => {
                            UserService.addsettled(this.ruleForm).then(res => {
                                if (res.code == 1) {
                                    this.stepActive = 2
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        })
                    } else {
                        // // console.log('error submit!!');
                        return false;
                    }
                });


            },
            handleChange(item) {
                this.ruleForm.area = CodeToText[item[0]]+CodeToText[item[1]]+CodeToText[item[2]]

            },
            resetForm(formName) {
                this.$confirm(`确定重置已输入数据？`).then(res => {
                    this.ruleForm.cnas_url = ''
                    this.$refs[formName].resetFields();
                })

            }
        },
        mounted() {
            let a;
            const b = new Promise((resolve, reject) => {
                // // console.log('promise1');
                resolve();
            }).then(() => {
                // // console.log('promise2');
            }).then(() => {
                // // console.log('promise3');
            }).then(() => {
                // // console.log('promise4');
            });
            a = new Promise(async (resolve, reject) => {
                // // console.log('22', a);
                await b;
                // // console.log(a);
                // // console.log('after1');
                await a
                resolve(true);
                // // console.log('after2');
            }).then(res => {
                // // console.log('res', res)
            });
            // // console.log('end');
        }
    }
</script>
<style  lang="scss">
    .laboratory{
        .preview{
            position: absolute;
            right: 0;
            bottom: 0;
            opacity: 0.8;
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
</style>
<style scoped lang="scss">

    .box-card{
      width: 800px;
        min-height: 800px;
        margin: 0 auto;
    }
</style>